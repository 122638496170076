<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Prelab Quiz - Density of Metals and Solutions</h2>

      <p class="mb-n3">
        a) What do you need to calculate and discuss in your error analysis? Choose one option:
      </p>

      <v-radio-group v-model="inputs.input1" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">b) How many parts are there to this lab?</p>

      <p>
        <v-select
          v-model="inputs.input2"
          :items="items2"
          item-text="text"
          item-value="value"
          label="Select Number of Parts"
          class="mb-0 mt-0 ml-4"
          style="width: 200px"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-n3">
        c) What type of substance will we be finding the density for in part 2 of this lab?
      </p>

      <v-radio-group v-model="inputs.input3" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options3"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        d) What approaches will be used to determine the densities of the solid? Choose all that
        apply:
      </p>

      <p v-for="option in options4" :key="'pt-4-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input4" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'HawkeyePrelabQuiz2',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: [],
      },
      options1: [
        {text: '$\\text{Only the R}^2\\text{ value from the graph in part 2}$', value: 'a'},
        {text: '$\\text{The R}^2 \\text{ values and \\% RSD for parts 1 and 2}$', value: 'b'},
        {text: '$\\text{Only the \\% error from part 1}$', value: 'c'},
        {
          text: '$\\text{The \\% error from part 1 and the R}^2\\text{ value from the graph in part 2}$',
          value: 'd',
        },
        {text: '$\\text{The \\%RSD and \\% error for parts 1 and 2}$', value: 'e'},
      ],
      items2: [
        {text: '1', value: '1'},
        {text: '2', value: '2'},
        {text: '3', value: '3'},
        {text: '4', value: '4'},
        {text: '5', value: '5'},
      ],
      options3: [
        {text: '$\\text{Gas}$', value: 'a'},
        {text: '$\\text{Solid}$', value: 'b'},
        {text: '$\\text{Solution}$', value: 'c'},
        {text: '$\\text{Plasma}$', value: 'd'},
      ],
      options4: [
        {text: '$\\text{Displacement}$', value: 'a'},
        {text: '$\\text{Calculating the volume using a caliper or ruler}$', value: 'b'},
        {text: '$\\text{Copying from a classmate or my lab partner}$', value: 'c'},
        {text: '$\\text{Google}$', value: 'd'},
        {text: '$\\text{Feeling if it appears denser than water}$', value: 'e'},
      ],
    };
  },
};
</script>
<style scoped>
.v-input--checkbox {
  display: inline-block !important;
}
</style>
